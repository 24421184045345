<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          Добавление приказа
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-autocomplete
                  v-model="newOrder.eduYear"
                  label="Год"
                  placeholder="Выберите значение из списка"
                  :items="years"
                  item-text="name"
                  hide-no-data
                  clearable
                  return-object
                  :error-messages="errorMessages.eduYear"
                  @input="$v.newOrder.eduYear.$touch()"
                  @blur="$v.newOrder.eduYear.$touch()"
                />

                <v-textarea
                  v-model="newOrder.description"
                  label="Описание приказа"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.description"
                  @input="$v.newOrder.description.$touch()"
                  @blur="$v.newOrder.description.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveNewOrder"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { catalogApi } from '@/api'
import _cloneDeep from 'lodash/cloneDeep'
import { required, maxLength } from 'vuelidate/lib/validators'
import { setValidateMessages } from '@/scripts/validation'

export default {
  name: 'CreateOrderDialog',

	props: {
    dialog: Boolean,
    loading: Boolean
	},

	data: () => ({
    newOrder: {
      eduYear: null,
      description: null
    },

    years: []
	}),

  async created() {
    await this.getYears()
  },

  validations() {
    return {
      newOrder: {
        eduYear: { required },
        description: { required, maxLength: maxLength(200) }
      }
    }
  },

	computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.newOrder.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.newOrder[key])
      })

      return allErrors
    }
	},

	methods: {
    async getYears() {
      try {
        const currentYear = new Date().getFullYear()
        this.years = await catalogApi.getEduYears()
        this.years = this.years.filter(el => [currentYear, currentYear + 1].includes(+el.name))
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveNewOrder() {
      this.$emit('saveNewOrder', _cloneDeep(this.newOrder))
    },

    resetModal() {
      this.$v.$reset()

      this.newOrder = {
        eduYear: null,
        description: null
      }
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>