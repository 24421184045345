<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-show="dataLoaded">
      <div class="info-block" ref="infoBlockOrders">
        <PageTitle title="Приказы" />

        <v-card class="base-card pa-5">
          <div class="d-flex justify-space-between">
            <v-autocomplete
              v-model="order"
              class="year-input mt-0"
              label="Год"
              placeholder="Выберите значение из списка"
              :items="orders"
              item-text="eduYear.name"
              hide-no-data
              hide-details
              return-object
            />

            <v-btn
              small
              color="primary"
              class="white--text px-4"
              @click="dialog = true"
            >
              <v-icon left small>mdi-plus</v-icon>Добавить
            </v-btn>
          </div>

          <div class="order-description">
            <strong>Описание приказа:</strong><br>
            <span>{{ orderDescription }}</span>
          </div>
        </v-card>
      </div>

      <OrdersTable
        :infoBlockHeight="infoBlockHeight"
        :order="order"
        :orders="orders"
        :specialities="uniqueSpecialities"
        :currentOrderYear="currentOrderYear"
      />

      <CreateOrderDialog
        :dialog="dialog"
        :loading="loading"
        @saveNewOrder="saveNewOrder"
        @closeDialog="closeCreateOrderDialog"
      />
    </div>
  </div>
</template>

<script>
import { commissionOrderApi, catalogApi } from '@/api'
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title.vue'
import OrdersTable from '@/components/orders/Table.vue'
import CreateOrderDialog from '@/components/orders/dialogs/CreateOrder.vue'

export default {
  name: 'Orders',

  metaInfo: {
    title: 'Приказы'
  },

  components: {
    PageTitle,
    OrdersTable,
    CreateOrderDialog
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.getCurrentOrder()
    await this.getOrders()
    await this.getSpecialities()
    this.dataLoaded = true
    this.setInfoBlockHeight()
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setInfoBlockHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setInfoBlockHeight)
  },

  data: () => ({
    dataLoaded: false,
    infoBlockHeight: 0,
    dialog: false,
    loading: false,
    order: null,
    currentOrder: null,

    orders: [],
    uniqueSpecialities: []
  }),

  computed: {
    currentOrderYear() {
      return +this.currentOrder?.eduYear?.name || null
    },

    orderDescription() {
      return this.order?.description
    }
  },

  methods: {
    async getCurrentOrder() {
      try {
        this.order = await commissionOrderApi.getCurrentYear()
        this.currentOrder = { ...this.order }
      } catch (e) {
        console.log(e.status)
      }
    },

    async getOrders() {
      try {
        this.orders = await commissionOrderApi.getOrders()
      } catch (e) {
        console.log(e.status)
      }
    },

    async getSpecialities() {
      try {
        const specialities = await catalogApi.getSpecialityTable()
        this.uniqueSpecialities = this.setUniqueSpecialities(specialities)
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveNewOrder(newOrder) {
      try {
        this.loading = true

        await commissionOrderApi.createOrder(newOrder)
        await this.getOrders()
        this.closeCreateOrderDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка создания приказа')
      } finally {
        this.loading = false
      }
    },

    setUniqueSpecialities(specialities) {
      return specialities.reduce((acc, cur) => {
        const speciality = acc.find(el => el.specialtyName === cur.specialtyName)

        speciality
          ? speciality.idArray.push(cur.id)
          : acc.push({ specialtyName: cur.specialtyName, idArray: [cur.id] })

        return acc
      }, [])
    },

    setInfoBlockHeight() {
      this.$nextTick(() => {
        const infoBlock = this.$refs.infoBlockOrders

        if (infoBlock) {
          this.infoBlockHeight = infoBlock.offsetHeight
        }
      })
    },

    closeCreateOrderDialog() {
      this.dialog = false
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.base-card .year-input {
  max-width: 666px;
}

.base-card .order-description {
  font-size: 14px;
  margin-top: 16px;
}

.info-block {
  max-height: none;
}
</style>