<template>
	<v-row justify="center" v-if="dataLoaded">
		<v-dialog :value="dialog" max-width="700" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="title accent--text pb-4">
          {{ specialityName }}
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 px-0">
                <v-data-table
                  ref="commisionListTable"
                  class="base-table"
                  fixed-header
                  hide-default-header
                  hide-default-footer
                  group-by="cycleStaffType.name"
                  :headers="headers"
                  :items="commissionList.list"
                  :items-per-page="-1"
                >
                  <template #group.header="{ items, isOpen, toggle }">
                    <th colspan="4" class="px-0">
                      <div style="height: 100%">
                        <div
                          class="custom-th group"
                          :title="isOpen ? 'Скрыть строки' : 'Показать строки'"
                          @click="toggle"
                        >
                          <div class="d-flex align-center">
                            <span>{{ firstChapterUpperCase(items[0].cycleStaffType.name) }}</span>

                            <v-icon size="20" color="accent">
                              {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </div>

                          <v-icon
                            v-if="commissionList.showAddBtn(items[0].cycleStaffType) && !showMode"
                            color="btnIcon"
                            class="add-btn"
                            size="22"
                            title="Добавить"
                            @click="addOrderEmployee($event, items[0].cycleStaffType)"
                          >
                            mdi-plus
                          </v-icon>
                        </div>
                      </div>
                    </th>
                  </template>

                  <template #item.employee="{ item }">
                    <v-autocomplete
                      :loading="item.findLoading"
                      :disabled="showMode"
                      v-model="item.employee"
                      class="employee pt-0 mt-0"
                      placeholder="Начните вводить ФИО (поиск от 3-х символов)"
                      :items="item.employeesList"
                      item-text="employeeInfo"
                      hide-details
                      hide-no-data
                      hide-selected
                      no-filter
                      clearable
                      return-object
                      @update:search-input="findEmployees(item, $event)"
                      @click:clear="$nextTick(() => item.clear())"
                    />
                  </template>

                  <template #item.actions="{ item, index }">
                    <v-icon
                      v-if="commissionList.showRemoveBtn(item) && !showMode"
                      color="lightGrey"
                      class="remove-btn"
                      size="22"
                      title="Удалить"
                      @click="commissionList.removeOrderEmployee(item)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </template>

                  <template slot="no-data">
                    <div class="py-3">Нет сотрудников</div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="accent"
            @click="closeDialog"
          >
            {{ showMode ? 'Закрыть' : 'Отмена' }}
          </v-btn>

          <v-btn
            v-if="!showMode"
						:loading="loading"
            color="success"
            text
            @click="saveCommissionOrderLines"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { commissionOrderApi } from '@/api'
import _debounce from 'lodash/debounce'
import { OrderEmployees, Employee } from '@/models'

export default {
  name: 'SpecialityDialog',

	props: {
		dialog: Boolean,
    speciality: Object,
    order: Object,
    showMode: Boolean
	},

	data: () => ({
    dataLoaded: false,
    loading: false,
    headers: [
      { value: 'employee', sortable: false, align: 'left' },
      { value: 'actions', sortable: false, align: 'center', width: '50px' }
    ],

    commissionList: new OrderEmployees()
	}),

	computed: {
    minSpecialityId() {
      return Math.min(...this.speciality?.idArray)
    },

    specialityIdArray() {
      return this.speciality?.idArray
    },

    specialityName() {
      return this.speciality?.specialtyName
    },

    yearId() {
      return this.order?.eduYear?.id
    },
	},

	methods: {
    async getCommissionOrderLines() {
      try {
        const data = await commissionOrderApi.getCommisionOrderLines(this.order.commissionOrderId, this.minSpecialityId)

        this.commissionList.buildFromAPI(data)
        this.dataLoaded = true
        this.$emit('dataLoaded')
      } catch (e) {
        console.log(e.status)
      }
    },

		async saveCommissionOrderLines() {
      try {
        this.loading = true

        const promises = this.specialityIdArray.map(async specialityId => {
          const body = this.commissionList.getForAPI(this.order.commissionOrderId, specialityId)
          await commissionOrderApi.saveCommisionOrderLines(body)
        })

        await Promise.all(promises)
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения приказа')
      } finally {
        this.loading = false
      }
    },

    firstChapterUpperCase(val) {
      return val ? val[0].toUpperCase() + val.slice(1) : null
    },

    findEmployees: _debounce(async function(item, val) {
      try {
        if (val?.length >= 3 && item?.employee.employeeInfo !== val) {
          item.findLoading = true

          const data = await commissionOrderApi.findEmployees({name: val})
          item.employeesList = data.map(el => Employee.buildFromAPI(el))
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        item.findLoading = false
      }
    }, 500),

    addOrderEmployee(e, cycleStaffType) {
      e.stopPropagation()
      this.commissionList.addOrderEmployee(cycleStaffType)
    },

    resetModal() {
      this.dataLoaded = false
      this.commissionList = new OrderEmployees()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    dialog(val) {
      val ? this.getCommissionOrderLines() : this.resetModal()
    }
  }
}
</script>

<style scoped>
.base-table {
  min-height: 395px;
  max-height: 395px;
  border-radius: 4px !important;
}

.base-table >>> td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.base-table >>> th, .custom-th {
  white-space: nowrap;
  height: 32px !important;
}

.base-table >>> tbody tr td:last-child {
  width: 51px !important;
}

.base-table >>> .custom-th {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.base-table >>> .custom-th.group {
  justify-content: space-between;
  background-color: #F0F0F0 !important;
  cursor: pointer;
}

.base-table >>> .custom-th.group span {
  font-weight: 700 !important;
  font-size: 14px !important;
}

.base-table >>> .add-btn, .remove-btn {
  margin-right: -3px;
}

.base-table >>> .employee {
  font-size: 14px;
}

.base-table >>> .employee .v-input__slot:before {
  border-top: none;
}

.base-table >>> .employee input[type=text]::placeholder {
  color: var(--accent-color) !important;
}
</style>