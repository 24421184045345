<template>
  <div>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="table-card mt-4">
          <v-card-title class="py-3">
            <span class="accent--text">Специальности</span>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="tableSearch"
              class="mt-0 pt-0 table-search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
              clearable
            />
          </v-card-title>

          <v-data-table
            ref="specialitiesTable"
            class="base-table"
            loading-text="Загрузка..."
            fixed-header
            hide-default-footer
            :search="tableSearch"
            :loading="tableLoading"
            :headers="headers"
            :items="specialities"
            :items-per-page="-1"
          >
            <template #item.actions="{ item }">
              <v-icon
                v-if="isAllowEdit"
                color="btnIcon"
                class="mr-2 edit-btn"
                size="22"
                title="Редактировать"
                @click="editOrder(item)"
              >
                mdi-pencil-outline
              </v-icon>

              <v-icon
                v-else
                color="btnIcon"
                class="mr-2 show-btn"
                size="22"
                title="Просмотреть"
                @click="showOrder(item)"
              >
                mdi-eye
              </v-icon>

              <v-icon
                color="btnIcon"
                class="copy-btn"
                size="22"
                title="Копировать"
                @click="copyOrder(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>

            <template slot="no-data">
              <div class="py-3">Нет приказов</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <OrderDialog
      :dialog="dialog"
      :speciality="selectedItem"
      :order="order"
      :showMode="showMode"
      @dataLoaded="tableLoading = false"
      @closeDialog="closeDialog"
    />

    <CopyOrderDialog
      :dialog="copyOrderDialog"
      :speciality="selectedItem"
      :order="order"
      :orders="orders"
      :specialities="specialities"
      @closeDialog="closeCopyOrderDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderDialog from '@/components/orders/dialogs/Order'
import CopyOrderDialog from '@/components/orders/dialogs/CopyOrder'

export default {
  name: 'SpecialitiesTable',

  components: {
    OrderDialog,
    CopyOrderDialog
  },

  props: {
    infoBlockHeight: Number,
    order: Object,
    orders: Array,
    specialities: Array,
    currentOrderYear: Number
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    headers: [
      { text: 'Специальность', value: 'specialtyName', sortable: true, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '110px' }
    ],

    tableSearch: null,
    dialog: false,
    copyOrderDialog: false,
    tableLoading: false,
    selectedItem: null,
    showMode: false
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId']),

    isAllowEdit() {
      if (!this.currentOrderYear) return false

      return [this.currentOrderYear, this.currentOrderYear + 1].includes(+this.order?.eduYear?.name)
    }
  },

  methods: {
    editOrder(item) {
      this.selectedItem = item
      this.dialog = true
      this.tableLoading = true
    },

    showOrder(item) {
      this.editOrder(item)
      this.showMode = true
    },

    copyOrder(item) {
      this.selectedItem = item
      this.copyOrderDialog = true
    },

    setTableHeight() {
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 241 + this.infoBlockHeight + this.techMessageHeight

      if (this.$refs?.specialitiesTable) {
        this.$refs.specialitiesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    closeDialog() {
      this.dialog = false
      this.showMode = false
      this.selectedItem = null
    },

    closeCopyOrderDialog() {
      this.copyOrderDialog = false
      this.selectedSpeciality = null
    }
  },

  watch: {
    infoBlockHeight() {
      this.setTableHeight()
    }
  }
}
</script>