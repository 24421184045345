<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          Копирование комиссии
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-autocomplete
                  v-model="copyOrder.eduYear"
                  label="Год"
                  placeholder="Выберите значение из списка"
                  :items="orders"
                  item-text="eduYear.name"
                  hide-no-data
                  clearable
                  return-object
                  :error-messages="errorMessages.eduYear"
                  @input="$v.copyOrder.eduYear.$touch()"
                  @blur="$v.copyOrder.eduYear.$touch()"
                />

                <v-autocomplete
                  v-model="copyOrder.speciality"
                  label="Специальность"
                  placeholder="Выберите значение из списка"
                  :items="specialities"
                  item-text="specialtyName"
                  hide-no-data
                  clearable
                  return-object
                  :error-messages="errorMessages.speciality"
                  @input="$v.copyOrder.speciality.$touch()"
                  @blur="$v.copyOrder.speciality.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveCopyOrder"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { commissionOrderApi } from '@/api'
import { setValidateMessages } from '@/scripts/validation'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CopyOrderDialog',

	props: {
    dialog: Boolean,
    speciality: Object,
    order: Object,
    orders: Array,
    specialities: Array
	},

	data: () => ({
    loading: false,

    copyOrder: {
      eduYear: null,
      speciality: null
    }
	}),

  validations() {
    return {
      copyOrder: {
        eduYear: { required },
        speciality: { required }
      }
    }
  },

	computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.copyOrder.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.copyOrder[key])
      })

      return allErrors
    }
	},

	methods: {
    async saveCopyOrder() {
      try {
        this.loading = true

        const minSourceSpecialityId = Math.min(...this.speciality?.idArray)
        const promises = this.copyOrder.speciality.idArray.map(async targetSpecialityId => {
          const body = {
            source: {
              eduYear: this.order.eduYear,
              specialtyTableId: minSourceSpecialityId
            },
            target: {
              eduYear: this.copyOrder.eduYear.eduYear,
              specialtyTableId: targetSpecialityId
            }
          }

          await commissionOrderApi.copyOrder(body)
        })

        await Promise.all(promises)
        this.$toastr('success', '', 'Состав комиссии скопирован')
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка копирования состава комиссии')
      } finally {
        this.loading = false
      }
    },

    resetModal() {
      this.$v.$reset()

      this.copyOrder = {
        eduYear: null,
        speciality: null
      }
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>